<template>
    <div class="view transactions">
        <div class="title">
            {{ $t('transaction_history.title') }}

        </div>
        <div id="scrollcheck" class="table">
            <table class="table profile-table">
                <thead>
                    <tr>
                        <th>{{ $t('transaction_history.transaction_time') }}</th>
                        <th>{{ $t('transaction_history.action') }}</th>
                        <th>{{ $t('transaction_history.amount') }}</th>
                        <th>{{ $t('transaction_history.status') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="transaction in transactions">
                        <td>{{ transaction.creationTime | moment("YYYY-MM-DD HH:mm:ss") }}</td>
                        <td>{{ transaction.type.name }}</td>
                        <td>{{ transaction.value | moneyFormat(true) }} {{ transaction.currency.code }}</td>
                        <td>{{ transaction.status.name }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import "TransactionsView.scss";
</style>
<script>
import axios from 'axios';
import $ from 'jquery';
export default {
    props: ['player'],
    data() {
        return {
            transactions: [], 
            page: 0,
            pageSize: 25,
            count: null,
        }
    },
    async mounted() {
    	this.getTransactions();
        $("#scrollcheck").on('scroll', (e) => {
            var elem = $(e.currentTarget);
            if (elem[0].scrollHeight - elem.scrollTop() == elem.outerHeight()) {
                this.loadNextPage();
            }
        });
    },
    async beforeDestroy() {
        $("#scrollcheck").off('scroll');
    },
    methods: {
        async loadNextPage() {
            let loadedCount = (this.page + 1) * this.pageSize;
            if(this.count > loadedCount) {
                console.log('aici');
                this.page++;
                this.getTransactions();
            }
        },
    	async getTransactions() {
    		try {
    			let response = await axios.post(`players/transactions?pageIndex=${this.page}&pageSize=${this.pageSize}`, {
	    				"isToday": false,
					    "startDate": this.$moment(Date.now()).subtract(30, 'days').format("YYYY-MM-DD"),
					    "endDate": this.$moment(Date.now()).format("YYYY-MM-DD"),
					    "typeIds": []
    			});
                //let newTransactions = [response.data.data[0]];
                let newTransactions = response.data.data;
                this.count = response.data.count;
    			this.transactions.push(...newTransactions);
                console.log(this.transactions);
    		} catch (error) {
    			console.log(error);
    			this.$toasted.error(this.$t('alerts.server_error'));
    		}
    	}
    }
}
</script>