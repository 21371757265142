<template>
<div class="view profile">
	<div class="title">
		{{ $t('profile.title') }}
	</div>
	<div class="row">
		<div class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
			<!-- Group -->
			<div class="group">
				<div class="name">{{ $t('profile.account_information') }}</div>
				<div class="fields">
					<div class="field">
						<div class="label">
							{{ $t('profile.username') }}:
						</div>
						<div class="value">
							{{ player.user.userName }}
						</div> 
					</div>
					<div class="field">
						<div class="label">
							{{ $t('profile.full_name') }}:
						</div>
						<div class="value">
							{{ player.fullName }}
						</div> 
					</div>
					<div class="field">
						<div class="label">
							{{ $t('profile.email') }}:
						</div>
						<div class="value">
							-
						</div> 
					</div>
				</div>
			</div>
			
			<!-- Group -->
			<div class="group">
				<div class="name">{{ $t('profile.funds_available') }}</div>
				<div class="fields">
					<div class="field">
						<div class="label">
							{{ $t('profile.deposit') }}:
						</div>
						<div class="value">
							{{ player.account.deposit | moneyFormat(true) }}
							{{ player.account.currency.code }}
						</div> 
					</div>
					<div class="field">
						<div class="label">
							{{ $t('profile.cashable') }}:
						</div>
						<div class="value">
							{{ player.account.cashable | moneyFormat(true) }}
							{{ player.account.currency.code }}
						</div> 
					</div>
					<div class="field">
						<div class="label">
							{{ $t('profile.bonus') }}:
						</div>
						<div class="value">
							{{ player.account.promo | moneyFormat(true) }}
							{{ player.account.currency.code }}
						</div> 
					</div>
					<div class="field">
						<div class="label">
							{{ $t('profile.balance') }}:
						</div>
						<div class="value">
							{{ player.account.balance | moneyFormat(true) }}
							{{ player.account.currency.code }}
						</div> 
					</div>
				</div>
			</div>
			<!-- Group -->
			<div class="group">
				<div class="name">{{ $t('profile.last_login') }}</div>
				<div class="fields">
					<div class="field">
						<div class="label">
							{{ $t('profile.ip_address') }}:
						</div>
						<div class="value">
							{{ player.user.lastLogin.remoteIpAddress }}
						</div> 
					</div>
					<div class="field">
						<div class="label">
							{{ $t('profile.login_time') }}:
						</div>
						<div class="value">
							{{ player.user.lastLogin.creationTime | moment("YYYY-MM-DD HH:mm:ss") }}
						</div> 
					</div>
				</div>
			</div>
		</div>
		<div class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
			

			

			<!-- Group -->
			<div class="group">
				<div class="name">{{ $t('profile.change_password') }}</div>
				<div class="fields">
					<br>
					<div class="field">
						<label>{{ $t('profile.current_password') }}</label>
    					<input type="password" v-model="credentials.currentPassword" class="form-control" style="border-color: #f2e3b3 !important;" >
					</div>
					<div class="field" style="margin-top: 16px;">
						<label>{{ $t('profile.new_password') }}</label>
    					<input type="password" v-model="credentials.newPassword" class="form-control" style="border-color: #f2e3b3 !important;" >
					</div>
					<div class="field" style="margin-top: 16px;">
						<label>{{ $t('profile.new_password_confirmation') }}</label>
    					<input type="password"  v-model="credentials.newPasswordConfirmation" class="form-control" style="border-color: #f2e3b3 !important;" >
					</div><br>
					<div class="field">
						<button @click="changePassword" type="button" class="btn btn-primary form-control" style="background-color: #f2e3b3 !important; color: #323448; text-transform: uppercase;">{{ $t('profile.update') }}</button>
					</div>
				</div>
			</div>

			<!-- Group -->
			<div class="group">
				<div class="name">{{ $t('profile.last_password_change') }}</div>
				<div class="fields" v-if="player.user.lastPasswordChange">
					<div class="field">
						<div class="label">
							{{ $t('profile.ip_address') }}:
						</div>
						<div class="value">
							{{ player.user.lastPasswordChange.remoteIpAddress }}
						</div> 
					</div>
					<div class="field">
						<div class="label">
							{{ $t('profile.login_time') }}:
						</div>
						<div class="value">
							{{ player.user.lastPasswordChange.creationTime | moment("YYYY-MM-DD HH:mm:ss") }}
						</div> 
					</div>
				</div>
				<div class="fields" v-else>
					<div class="field">
					{{ $t('profile.no_password_change') }}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>
<style lang="scss">
@import "ProfileView.scss";
</style>
<script>
import axios from 'axios';
export default {
	props: ['player'],
	data() {
		return {
			credentials: {
				currentPassword: '',
				newPassword: '',
				newPasswordConfirmation: '',
			}
		}
	},
	methods: {
		async changePassword() {
			try {
				let response = await axios.post('players/changePassword', this.credentials);
				this.$toasted.success("Password changed");

				this.credentials = {
					currentPassword: '',
					newPassword: '',
					newPasswordConfirmation: '',
				}

				this.$emit('update');
			} catch (error) {
				if (error.response && error.response.status == 400) {
                    if (error.response.data.errors) {
                        for (let key in error.response.data.errors) {
                            let responseError = error.response.data.errors[key];
                            responseError.forEach(message => {
                                this.$toasted.error(message);
                            })
                        }
                    }
                } else {
                    console.log(error);
                    this.$toasted.error(this.$t('alerts.server_error'));
                }
			}
		}
	}
}
</script>