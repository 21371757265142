// Libs
import Vue from 'vue';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Axios from 'axios'
import Toasted from 'vue-toasted';
import 'bootstrap-icons/font/bootstrap-icons.css'
import VueCarousel from 'vue-carousel';

// Modules
import store from './store';
import router from './router';

// SCSS Files 
import './assets/scss/style.scss';
import './assets/scss/playstyle.scss';
import './assets/scss/btn-layout.scss';
// Import theme based on client env
require(`./assets/themes/${process.env.VUE_APP_THEME}`);

//import qrcodereader from 'qrcodereader';

// App
import App from './App.vue';
Vue.config.productionTip = false;

// Filters
import filters from './filters/IndexFilters.js';
filters.forEach(f => {
    Vue.filter(f.name, f.execute)
});

// Utils

import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
/*moment.tz.setDefault('Etc/UTC');*/
moment.tz.setDefault('Europe/Bucharest');
Vue.use(VueMoment, {
    moment,
})
//Vue.use(require('vue-moment'));

// Localization
import VueI18n from 'vue-i18n'
Vue.use(VueI18n);
import en from './locales/en.json';
import ro from './locales/ro.json';
const messages = Object.assign({
    en: en,
    ro: ro,
});
const i18n = new VueI18n({
  locale: 'en', 
  //fallbackLocale: 'en',
  messages,
})



Vue.use(VueCarousel);
Vue.use(Toasted, {
    theme: "toasted-primary",
    position: "bottom-center",
    duration: 3000
});
Vue.prototype.$http = Axios;
//Vue.prototype.$http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Vue.prototype.$http.defaults.headers["Content-Type"] = "application/json";
//Vue.prototype.$http.defaults.headers["ClientCode"] = "R2ADevWebCasino"; // UnigensWebCasino 


//Vue.prototype.$http.defaults.headers["Auth-Key"] = "a6abc6c14df6cf31e09c2728549529a745a47436"; 
//Vue.prototype.$http.defaults.headers["Client-Id"] = "CASINO2.0"; 

Vue.prototype.$http.defaults.crossDomain = true;
Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_API_LOCALSERVER + '/api/v1/';
Vue.prototype.getImage = function(fileName) {
    return 'https://r2a-new.cloud.ro/business/api/v1/media/' + fileName;
}

// Get app images





/*Vue.prototype.getAppImage = function(fileKey) {
    Vue.prototype.$http.get('/unigens/download_picture_by_key?path=CASINO_PLAYER&key=' + fileKey)
        .then(res => {
            console.log(res);
            return res.status;
            //return res.data;
        })
        .catch(err => {
          console.log(err);
        });

}*/

const token = localStorage.getItem('token');
if (token) {
    const auth = JSON.parse(token);
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + auth.access_token;
}

// Socket.io
import VueSocketIO from 'vue-socket.io'
Vue.use(new VueSocketIO({
    debug: false,
    connection: process.env.VUE_APP_API_SIGNAL,
    vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_'
    },
    options: {
        query: {
            client_type: 'casinoClient',
        }
    }
}))

// Init app
new Vue({
    router,
    store,
    i18n,
    created() {
        AOS.init({
            duration: 300,
            easing: 'ease',
            once: true,
            offset: 0,
            anchorPlacement: 'top',
        });
    },
    render: function(h) { return h(App) }
}).$mount('#app')