<template>
    <div class="view transactions">
        <div class="title">
            {{ $t('game_history.title') }}
        </div>
        <div class="table">
            <table class="table profile-table">
                <thead>
                    <tr>
                        <th>{{ $t('game_history.session_time') }}</th>
                        <th>{{ $t('game_history.machine_group') }}</th>
                        <th>{{ $t('game_history.machine') }}</th>
                        <th>{{ $t('game_history.games') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="session in sessions">
                        <td>{{ session.startDate | moment("YYYY-MM-DD HH:mm:ss") }}</td>
                        <td>{{ session.groupName }}</td>
                        <td>{{ session.serial }} #{{ session.number }}</td>
                        <td>{{ session.totalGamesDelta | moneyFormat(true) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import "SessionsView.scss";
</style>
<script>
import axios from 'axios';
export default {
    props: ['player'],
    data() {
        return {
            sessions: null,
        }
    },
    async mounted() {
    	this.getSessions();
    },
    methods: {
    	async getSessions() {
    		try {
    			let response = await axios.post('players/sessions?pageIndex=0&pageSize=25', {
					    "startDate": this.$moment(Date.now()).subtract(7, 'days').format("YYYY-MM-DD"),
					    "endDate": this.$moment(Date.now()).format("YYYY-MM-DD"),
    			});
    			this.sessions = response.data;
    		} catch (error) {
    			console.log(error);
    			this.$toasted.error(this.$t('alerts.server_error'));
    		}
    	}
    }
}
</script>